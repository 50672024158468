
.goods__wr {
    overflow: auto;
    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: auto;
    margin-right:auto;
    max-width: 860px;
}
.goods__our-best {
    max-width: 802px;
    height: 590px;  
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;    
    background-color: #fff;    
    display: flex;
    justify-content: space-between;   
    flex-wrap: wrap;
    flex-direction:row;
}
@media screen and (max-width: 715px) {
    .goods__our-best {
        justify-content: space-around;  
    }    
}
.goods__stock {
    width: 220px;
    height: 260px;
    background-color: #fff;
    margin-bottom: 60px;   
    position: relative;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
.goods__coffeeshop {
    position: absolute;
    width: 151px;
    height: 134px;
    top: 39%;
    right: 50%;
    transform: translate(50%, -50%);
}
.goods__cofeeshop {
    text-align: end;
    padding-right: 25px;
}
.goods__cofee {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-top: 170px;
   
    
}
.goods__cofeeprice {
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    top: 85%;
    right: 10%;
}
