
.our-best {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    height: 495px;
    background-image: url(../images/Mask\ Group-2.jpg);
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
.our-best h3 {
    color: black;
    padding-top: 80px;
    text-align: center;
    position: absolute;
}
.stock {
    width: 220px;
    min-width: 220px;
    height: 240px;
    margin-right: 34px;
    margin-left: 34px;
    background: rgba(255, 255, 255, 0.65);
    border-radius: 8px;
    text-align: center;
    position: relative;
}
.coffeeshop {
    position: absolute;
    top: 39%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 151px;
    height: 134px;
}
.cofee {
    font-weight: 400;
    font-size: 14px;
    margin-top: 170px;
}
.cofeeprice {
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    top: 85%;
    right: 10%;
}
.o__b__wrap {
    display: flex;
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
    width: 1153px;
    height: 320px;    
    overflow: auto;
}
    @media screen and  (max-width: 1435px) {
    .o__b__wrap {
        width: 893px;
    }
}
    @media screen and (max-width: 892px) {
    .o__b__wrap {    
        width: 600px;
    }
}
    @media screen and (max-width: 629px) {
    .o__b__wrap {
        width: 320px;
    }
}

