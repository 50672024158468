.filter__menu {
    max-width: 720px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 60px;
    padding-left: 10px;
}
@media screen and (max-width : 650px) {
    .filter__menu {
        display: block;
        margin-top: 30px;
        padding-left: 0px;
        padding-right: 0px;;
        margin: 0 auto;
    } 
}

.sort {
    width: 75px;
    height: 30px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px 0px 0px 4px;
    display: flex;
    margin-right: 5px;
    cursor: pointer;
}
.sortir {
    display: flex;
    width: 284px;
    height: 30px;
    margin-left: 20px;
}
@media screen and (max-width : 650px) {
    .sortir {
        padding-left: 0px;
        padding-right: 0px;;
        margin: 10px auto;
    } 
}
.sortir button {
    width: 180px;
    height: 30px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px 0px 0px 4px;
    display: flex;
}
.filter {
    display: flex;
    width: 326px;
    height: 30px;    
}
@media screen and (max-width : 650px) {
    .filter {
        padding-left: 0px;
        padding-right: 0px;;
        margin: 0 auto;
    } 
}
.here {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
    margin: auto;
    cursor: pointer;

}
.look {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    margin-right: 20px;
}
.sort p {
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
}
.btn-light {
border: 1px solid rgba(0, 0, 0, 0.5);
}
.btn-outline-light {
border: none;
}

