.item__page__wrapper {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    max-width: 1440px;
    height: 455px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -20px;
    padding-right: 15px;
}
@media screen and (max-width: 880px) {
    .item__page__wrapper {
        flex-wrap: wrap;
        height: 910px;
    }
}
.item__page {
    display: flex;
    margin-top: 50px;
    width: 392px;
    height: 355px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
}
.item__page__tex {
    /* display: flex; */
    margin-top: 50px;   
    margin-left: 42px; 
    width: 430px;
    height: 355px;    
    padding-left: 15px;    
}
@media screen and (max-width: 880px) {
    .item__page__tex {
        margin: 10px auto;
    }
}
.about {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;;
}
.item__page__h__text {
    margin-top: 25px;   
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
.item__page__country__name {
    font-weight: 300;
}
.item__page__desc__name {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
}
.item__page__desc {
    margin-top: -10px;
}
.item__page__price {
    margin-top: 30px;  
}
.item__page__price__text {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
.item__page__price__name {
    font-weight: 700;
    font-size: 23px;
    margin-left: 7px;
}
.close__page {
    position: absolute;
    font-family: Tahoma, sans-serif;
    font-weight: 500;
    font-size: 50px;
    color: rgb(214, 207, 207);
    transform: rotate(45deg);
    left: 80%;
}
.close__page:hover {
    cursor: pointer;
    font-size: 60px;    
    color: rgb(8, 8, 8);
    transform: rotate(90deg); 
    transition: 0.7s; 
}
