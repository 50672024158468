
.f__y__pleasure {
    max-width: 1440px;
    height: 260px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    background-image: url('../images/coffee.png');
    text-align: center;
}
h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    padding-top: 130px;
    color: white;   
}
.f__y__about__our__goods {
    margin-top: 70px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
@media screen and (max-width: 700px) {
    .f__y__about__our__goods {
        margin-top: 30px;
    }
}
.f__y__wrapper {
    width: 697px;
    height: 355px;
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 700px) {
    .f__y__wrapper {
       flex-wrap: wrap; 
       justify-content: center;
       height: auto;
    }
}
.f__y__about__coffee {
    width: 100%;
    height: 415px;
    display: flex;   
    justify-content: center;
   
}
@media screen and (max-width: 700px) {
    .f__y__about__coffee {
       flex-wrap: wrap; 
       margin-bottom: 10px;
       height: auto;
    }
}
.f__y__about__img {
    width: 272px;
    height: 353px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25); 
    margin-left: -7px; 
       
}
.f__y__text__wrapper {
    width: 345px;
    height: 355px;   
    padding-top: 10px;
    margin-left: 10px;
   
}
.f__y__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 346px;
    height: 232px; 
    margin-left: 5px;    
}
.f__y__text h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 10px;
}
.f__y__text p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 25px;;
}
.f__y__line {
    /* display: block; */
    margin: 0 auto;
    width: 240px;
    height: 0px;
    border: black  solid 0.5px;
}
.f__y__goods__wr {
    margin-bottom: 50px;
    margin-top: 50px;
    
  }
.f__y__goods__our-best {
    max-width: 802px;      
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;     
    background-color: #fff;    
    display: flex;
    justify-content: space-between;   
    flex-wrap: wrap;
    flex-direction: row;    
}
@media screen and (max-width: 700px) {
    .f__y__goods__our-best {       
       justify-content: space-around;
       
    }
}
.block {
    position: absolute;
    /* width: 1440px; */
    margin-bottom: 50px;
    margin-top: 50px;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 1;
    cursor: pointer;

}