
.about__our__beans {
    margin-top: 70px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}
@media screen and (max-width: 697px) {
    .about__our__beans {
        margin-top: 30px;
    } 
} 
.wrapper {
    width: 697px;
    height: 355px;
    display: flex;
    justify-content: space-between;
    margin-left: 45px;
}
@media screen and (max-width: 697px) {
    .wrapper {
        display: block; 
        height: 700px;
        margin: 0 auto;
        
    }    
}
.about__coffee {
    width: 100%;
    height: 415px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 697px) {
    .about__coffee {            
        height: 700px;
        margin: 0 auto;
    } 
} 
.about__img {
    width: 272px;
    height: 353px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);     
}
@media screen and (max-width: 697px) {
    .about__img {
        margin: 0 auto;
    } 
} 
.about__text {
    width: 345px;
    height: 355px;   
    padding-top: 10px;
   /*  padding-left: 80px; */
  }
  @media screen and (max-width: 697px) {
    .about__text {
        margin: 0 auto;
    } 
} 
.text {    
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 346px;
    height: 232px;
}
.about__text h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 10px;
}
.about__text p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 25px;;
}
.line {
    /* display: block; */
    margin: 0 auto;
    width: 240px;
    height: 0px;
    border: black  solid 0.5px;
}
